<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-card elevation="0" max-width="1100" width="100%" class="transparent mb-5">
        <v-toolbar flat class="transparent">
          <h1 class="headline">{{ $t('menu.servicios') }}</h1>
          <v-spacer />
          <v-btn
            depressed
            rounded
            color="primary"
            dark
            @click="
              dialog_new.dialog = true;
              resetNuevo();
            "
          >
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{ $t('common.nuevo_servicio') }}
          </v-btn>
        </v-toolbar>

        <div id="pageList">
          <v-container grid-list-xl fluid>
            <v-layout wrap>
              <!-- Mensajería -->
              <v-flex v-if="mensajeria_activo" xs12 lg12 md12>
                <v-toolbar
                  dark
                  :color="
                    mensajeria.estado_inicial === 1 || mensajeria.estado_inicial === true
                      ? 'pink darken-3'
                      : 'pink lighten-3'
                  "
                  dense
                >
                  <v-toolbar-title class="white--text"
                    ><v-icon>mdi-message-text</v-icon>&nbsp;{{ $t('menu.messaging') }}</v-toolbar-title
                  >
                  <v-spacer />
                  <v-chip
                    :color="
                      mensajeria.estado_inicial === 1 || mensajeria.estado_inicial === true
                        ? 'pink darken-3'
                        : 'pink lighten-3'
                    "
                    text-color="white"
                    style="text-transform: uppercase"
                  >
                    {{
                      mensajeria.estado_inicial === 1 || mensajeria.estado_inicial === true
                        ? $t('common.activado')
                        : $t('common.desactivado')
                    }}
                  </v-chip>
                </v-toolbar>
                <v-card>
                  <br clear="all" />
                  <div class="form-margen">
                    <v-layout wrap>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="mensajeria.descripcion"
                          class="form-margen"
                          :label="$t('common.descripcion')"
                          @input="cambiaMensajeria()"
                        />
                      </v-flex>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="mensajeria.precio"
                          :label="
                            mensajeria.precio > 0
                              ? $t('common.precio')
                              : $t('common.precio') + ' (' + $t('common.gratuita') + ')'
                          "
                          type="number"
                          step="0.01"
                          min="0"
                          append-icon="mdi-currency-eur"
                          @input="cambiaMensajeria()"
                        />
                      </v-flex>
                      <v-flex xs6 style="margin-top: -25px">
                        <v-switch
                          v-model="mensajeria.estado"
                          :label="
                            mensajeria.estado === 0 || mensajeria.estado === false
                              ? 'Estado: Inactivo'
                              : 'Estado: Activo'
                          "
                          color="pink"
                          @change="cambiaMensajeria"
                        />
                      </v-flex>
                      <v-flex xs6 style="text-align: right; margin-top: -15px">
                        <v-btn v-if="mensajeria.modificado" color="green" dark @click="guardarMensajeria">{{
                          $t('common.save')
                        }}</v-btn>
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card>
              </v-flex>
              <!-- Tipos Videoconsultas -->
              <v-flex v-for="(videoconsulta, index) in videoconsultas" :key="index" xs12 lg12 md12>
                <v-toolbar dark :color="videoconsulta.estado_inicial === 1 ? color_video : color_video_disabled" dense>
                  <v-toolbar-title class="white--text"
                    ><v-icon>mdi-video</v-icon>&nbsp;{{ videoconsulta.nombre }}</v-toolbar-title
                  >
                  <v-spacer />
                  <v-chip
                    :color="
                      videoconsulta.estado_inicial === 1 || videoconsulta.estado_inicial === true
                        ? color_video
                        : color_video_disabled
                    "
                    text-color="white"
                    style="text-transform: uppercase"
                  >
                    {{
                      videoconsulta.estado_inicial === 1 || videoconsulta.estado_inicial === true
                        ? $t('common.activado')
                        : $t('common.desactivado')
                    }}
                  </v-chip>
                  <v-btn v-if="videoconsulta.es_estandar !== 1" icon @click="abrirDialogDelete(index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card>
                  <br clear="all" />
                  <div class="form-margen">
                    <v-layout wrap>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="videoconsulta.nombre"
                          :label="$t('common.name')"
                          class="textarea_videoconsulta"
                          @input="cambiaVideoconsulta(index, 'editar')"
                        />
                      </v-flex>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="videoconsulta.duracion"
                          :label="$t('tipovideo.duracion')"
                          type="number"
                          min="1"
                          max="1440"
                          suffix="min."
                          append-icon="mdi-clock-outline"
                          @input="cambiaVideoconsulta(index, 'editar')"
                        />
                      </v-flex>
                      <v-flex xs12 lg12 md12>
                        <v-textarea
                          v-model="videoconsulta.descripcion"
                          :label="$t('common.descripcion')"
                          class="textarea_videoconsulta"
                          rows="2"
                          @input="cambiaVideoconsulta(index, 'editar')"
                        />
                      </v-flex>
                      <v-flex xs6 style="margin-top: -25px">
                        <v-switch
                          v-model="videoconsulta.estado"
                          :label="
                            videoconsulta.estado === 0 || videoconsulta.estado === false
                              ? 'Estado: Inactivo'
                              : 'Estado: Activo'
                          "
                          color="primary"
                          @change="cambiarEstado(index)"
                        />
                      </v-flex>
                      <v-flex xs6 style="text-align: right; margin-top: -15px">
                        <v-btn
                          v-if="videoconsulta.modificado"
                          color="green"
                          dark
                          @click="guardarVideonconsulta(index)"
                          >{{ $t('common.save') }}</v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </div>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
          <!-- Modal Confirmar  -->
          <span slot="widget-content">
            <v-dialog v-model="dialog_confirm.dialog" dialog_confirm max-width="500px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ dialog_confirm.name }}</span>
                </v-card-title>
                <v-divider />
                <v-card-text>
                  <p>{{ dialog_confirm.text }}</p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="grey darken-1" text @click.native="dialog_confirm.dialog = false"
                    ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
                  >
                  <v-btn
                    color="red darken-1"
                    text
                    @click.native="
                      dialog_confirm.dialog = false;
                      confirmaDialogo('confirm');
                    "
                    ><v-icon>{{ dialog_confirm.icon }}</v-icon> {{ $t('common.confirm') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>

          <!-- Modal Nuevo -->
          <span slot="widget-content">
            <v-dialog v-model="dialog_new.dialog" dialog_new max-width="600px" scrollable>
              <v-card>
                <v-card-title>
                  <span class="headline">{{ $t('tipovideo.nuevo_titulo') }}</span>
                </v-card-title>
                <v-card-text>
                  <v-container grid-list-md>
                    <v-layout wrap>
                      <v-flex xs12 lg12 md12>
                        <v-text-field
                          v-model="nuevotipo.nombre"
                          :rules="[rules.required]"
                          :label="$t('common.name')"
                          type="text"
                        />
                      </v-flex>
                      <v-flex xs12 lg12 md12>
                        <v-textarea
                          v-model="nuevotipo.descripcion"
                          :label="$t('common.descripcion')"
                          class="textarea_videoconsulta"
                          rows="2"
                        />
                      </v-flex>
                      <v-flex xs12 lg6 md6>
                        <v-text-field
                          v-model="nuevotipo.duracion"
                          :label="$t('common.duracion')"
                          type="number"
                          min="1"
                          max="1440"
                          suffix="min."
                          append-icon="mdi-clock-outline"
                          @input="cambiaVideoconsulta(0, 'nuevo')"
                        />
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="grey darken-1" text @click.native="dialog_new.dialog = false"
                    ><v-icon>mdi-chevron-left</v-icon> {{ $t('common.cancel') }}</v-btn
                  >
                  <v-btn color="primary" text @click.native="confirmaDialogo('nuevo')"
                    ><v-icon>mdi-content-save</v-icon> {{ $t('common.confirm') }}</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </span>

          <!-- Snackbar para mostrar avisos -->
          <v-snackbar
            v-model="snackbar"
            :timeout="timeout"
            :color="alerta.color"
            top="top"
            :multi-line="mode === 'multi-line'"
            :vertical="mode === 'vertical'"
          >
            <v-icon v-if="alerta.icono !== ''">{{ alerta.icono }}</v-icon
            >&nbsp;{{ alerta.texto }}
          </v-snackbar>

          <div class="text-center">
            <v-dialog v-model="dialogSalirSinGuardar" width="500">
              <v-card>
                <v-card-title class="headline lighten-2">{{ $t('common.titulo_salir_sin_guardar') }}</v-card-title>
                <v-divider />
                <v-card-text>{{ $t('common.salir_sin_guardar') }}</v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn color="primary" dark text @click="dialogSalirSinGuardar = false">{{
                    $t('common.cancel')
                  }}</v-btn>
                  <v-btn color="primary" dark text @click="confirmarSalir">{{ $t('common.confirm') }}</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </div>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import {
  listServicesUrl,
  tiposVideoconsulta,
  saveSettingServiceUrl,
  saveMessageServiceUrl,
  getHeader,
} from '@/config/config';

export default {
  data: () => ({
    dialogSalirSinGuardar: false,
    pageNext: {},
    authUser: JSON.parse(window.localStorage.getItem('auth_ehealth')),
    loading: true,
    videoconsultas: [],
    nuevotipo: { id: 0, descripcion: '', nombre: '', duracion: '', precio: '', tipoVideo: 0 },
    dialog_confirm: { name: '', text: '', icon: '', tipo: 0, dialog: false },
    dialog_new: { dialog: false },
    snackbar: false,
    timeout: 5000,
    mode: '',
    mensajeria_activo: false,
    mensajeria: {
      estado: 0,
      estado_inicial: 0,
      precio: 0,
      modificado: false,
      descripcion: '',
    },
    alerta: {
      texto: '',
      icono: '',
      color: 'info',
    },
    rules: {},
    color_video: 'blue',
    color_video_disabled: 'blue lighten-3',
  }),

  created() {
    this.rules = {
      required: value => !!value || this.$t('common.obligatorio'),
    };
    const authUser = JSON.parse(window.localStorage.getItem('auth_ehealth'));
    if (authUser !== null) {
      this.$router.push('/ajustes/videoconsultas');
    }
    if (authUser !== null && authUser.nombreEmpresa === 'Docline Hospital') {
      this.mensajeria_activo = true;
    }
    this.authUser?.id &&
      this.$http
        .get(listServicesUrl, { headers: getHeader() })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.videoconsultas = response.data.videoconsultationsServices;
            this.mensajeria = response.data.messageService;
            this.mensajeria.estado_inicial = response.data.messageService.estado;
            this.videoconsultas.forEach(function(videconsulta) {
              videconsulta.estado_inicial = videconsulta.estado;
              videconsulta.dias = ['0', '1', '2', '3', '4', '5', '6'];
              videconsulta.modificado = false;
            });
          }
        })
        .catch(() => {
          this.$toast.error(this.$t('errors.try_again'));
        });
  },

  methods: {
    cambiarEstado(tipo) {
      this.videoconsultas[tipo].modificado = true;
    },

    cambiaMensajeria() {
      this.mensajeria.modificado = true;
      if (this.mensajeria.precio === '' || this.mensajeria.precio < 0) {
        this.mensajeria.precio = 0;
      }
    },

    cambiarEstadoMensajeria() {
      this.mensajeria.estado = this.mensajeria.estado === 1 ? 2 : 1;
      this.mensajeria.modificado = true;
    },

    cambiaVideoconsulta(tipo, t) {
      const step = 5;
      if (t === 'nuevo') {
        this.nuevotipo.precio = this.nuevotipo.precio === '' || this.nuevotipo.precio < 0 ? 0 : this.nuevotipo.precio;
        this.nuevotipo.duracion =
          this.nuevotipo.duracion === '' || this.nuevotipo.duracion < 1 ? 1 : this.nuevotipo.duracion;
        console.log(Math.ceil(this.nuevotipo.duracion / step) * step);
      } else {
        this.videoconsultas[tipo].modificado = true;
        if (this.videoconsultas[tipo].precio === '' || this.videoconsultas[tipo].precio < 0) {
          this.videoconsultas[tipo].precio = 0;
        } else if (this.videoconsultas[tipo].duracion === '' || this.videoconsultas[tipo].duracion < 1) {
          this.videoconsultas[tipo].duracion = 1;
        }
      }
    },
    guardarMensajeria() {
      if (this.mensajeria.estado_inicial !== this.mensajeria.estado && this.mensajeria.estado === false) {
        this.dialog_confirm.name = this.$t('tipovideo.guardarmensajeria');
        this.dialog_confirm.text = this.$t('tipovideo.confirm_desactivar_mensajeria');
        this.dialog_confirm.icon = 'mdi-content-save';
        this.dialog_confirm.dialog = true;
        this.dialog_confirm.tipo = 'mensajeria';
      } else {
        this.postSaveTipo('mensajeria');
      }
    },
    resetNuevo() {
      this.nuevotipo.descripcion = '';
      this.nuevotipo.nombre = '';
      this.nuevotipo.duracion = '';
      this.nuevotipo.precio = '';
    },

    postSaveTipo(tipo) {
      if (tipo === -1 && this.nuevotipo.nombre === '') {
        this.alerta.texto = this.$t('errors.form_invalid');
        this.alerta.color = 'warning';
        this.alerta.icono = 'mdi-alert-circle';
        this.snackbar = true;
      } else {
        this.dialog_new.dialog = false;
        const postTipo =
          tipo === 'mensajeria' ? this.mensajeria : tipo === -1 ? this.nuevotipo : this.videoconsultas[tipo];
        postTipo.tipo = tipo === 'mensajeria' ? 'mensajeria' : 'videoconsulta';
        const url = tipo === 'mensajeria' ? saveMessageServiceUrl : saveSettingServiceUrl;
        this.$http.post(url, { user_id: this.authUser.id, tipo: postTipo }, { headers: getHeader() }).then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.alerta.texto =
              tipo === 'mensajeria'
                ? this.$t('success.save_tipomensajeria')
                : this.$t('success.save_tipovideo', {
                    name: this.$t('videoconsulta.singular'),
                  });
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-check';
            if (tipo === 'mensajeria') {
              this.mensajeria.modificado = false;
              this.mensajeria.estado_inicial = this.mensajeria.estado;
            } else if (tipo === -1) {
              // nuevo tipo
              this.videoconsultas.push(response.data.tipo);
            } else {
              // editar
              this.videoconsultas[tipo].modificado = false;
              this.videoconsultas[tipo].estado_inicial = this.videoconsultas[tipo].estado;
            }
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        });
      }
    },

    postDeleteTipo(tipo) {
      this.$http
        .post(
          tiposVideoconsulta,
          { user_id: this.authUser.id, accion: 'eliminar', tipo_id: this.videoconsultas[tipo].id },
          { headers: getHeader() }
        )
        .then(response => {
          if (response.status === 200 && response.data.estado === '1') {
            this.alerta.texto = this.$t('success.delete_common');
            this.alerta.color = 'success';
            this.alerta.icono = 'mdi-delete';
            this.videoconsultas.splice(tipo, 1);
          } else {
            this.alerta.texto = this.$t('errors.try_again');
            this.alerta.color = 'error';
            this.alerta.icono = 'mdi-alert-circle';
          }
          this.snackbar = true;
        });
    },

    confirmaDialogo(tipo_dialog) {
      if (tipo_dialog === 'nuevo') {
        this.postSaveTipo(-1);
      } else {
        if (this.dialog_confirm.icon === 'save') {
          this.postSaveTipo(this.dialog_confirm.tipo);
        } else {
          this.postDeleteTipo(this.dialog_confirm.tipo);
        }
      }
    },

    guardarVideonconsulta(tipo) {
      if (
        this.videoconsultas[tipo].estado_inicial !== this.videoconsultas[tipo].estado &&
        this.videoconsultas[tipo].estado === 2
      ) {
        this.dialog_confirm.name = this.$t('tipovideo.guardar');
        this.dialog_confirm.text = this.$t('tipovideo.confirm_desactivar');
        this.dialog_confirm.icon = 'mdi-content-save';
        this.dialog_confirm.dialog = true;
        this.dialog_confirm.tipo = tipo;
      } else {
        this.postSaveTipo(tipo);
      }
    },

    abrirDialogDelete(tipo) {
      this.dialog_confirm.name = this.$t('tipovideo.confirm_delete_titulo');
      this.dialog_confirm.text = this.$t('tipovideo.confirm_delete_texto');
      this.dialog_confirm.icon = 'mdi-delete';
      this.dialog_confirm.dialog = true;
      this.dialog_confirm.tipo = tipo;
    },

    confirmarSalir() {
      this.dialogSalirSinGuardar = false;
      this.pageNext();
    },
  },
  beforeRouteLeave(to, from, next) {
    let vistaModificada = false;
    if (to['name'] !== 'Login') {
      for (let i = 0; i < this.videoconsultas.length; i++) {
        if (this.videoconsultas[i].modificado) {
          vistaModificada = true;
        }
      }
    }
    if (vistaModificada) {
      this.dialogSalirSinGuardar = true;
      this.pageNext = next;
      next(false);
    } else {
      next();
    }
  },
};
</script>

<style scoped lang="css">
.boton_save:hover {
  cursor: pointer !important;
}

.btn_float {
  z-index: 5;
  bottom: 60px;
  right: 8px;
}

.form-margen {
  margin-left: 15px;
  margin-right: 15px;
}
</style>
